<template>
	<v-form ref="form" v-model="isvalid" lazy-validation>
		<v-card tile>
			<v-toolbar flat dark color="primary">
				<v-toolbar-title>Update Billing Request - {{batchDetails.billing_request_no}} </v-toolbar-title>
				<v-spacer/>
				<v-btn icon dark @click="close()">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
			<v-card-text>
				<v-container grid-list-md>
					<v-layout v-if="billingDetails[0].reject_remarks">
						<v-flex xs12 sm12 md12>
							<v-alert type="error" outlined>
								This Billing has been rejected: Reason - {{billingDetails[0].reject_remarks}}
							</v-alert>
						</v-flex>
					</v-layout>
					<h4>Billing Information</h4> 
					<v-layout wrap>
						<v-flex xs12 sm12 md4>
							<v-text-field
								v-model="billingRequestNumber"
								label="Request No."
								readonly
							></v-text-field>
						</v-flex>
						<v-flex xs12 sm12 md4>
							<v-menu
									v-model="req_date"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									min-width="290px"
									:close-on-content-click="false"
								>
									<template v-slot:activator="{ on }">
										<v-text-field
											label="Request Date"
											v-model="request_date"
											required
											return-masked-value
											v-on="on">
										</v-text-field>
									</template>
									<v-date-picker
										color="primary"
										v-model="request_date"
										@change="req_date === false"
									></v-date-picker>
								</v-menu>
							</v-flex>
						<v-flex xs12 sm12 md4>
							<v-autocomplete
								class="remove-underline"
								v-model="payee_id"
								:items="filteredItems"
								editable
								hide-details
								item-text="payee_desc"
								item-value="id"
								label="Customer Name"
								@change="setDetails()"
							></v-autocomplete>
						</v-flex>
						<v-flex xs12 sm12 md4>
							<v-text-field
								v-model="business_style"
								editable
								label="Business Style"
								readonly
							>
							</v-text-field>
						</v-flex>
						<v-flex xs12 sm12 md4>
							<v-text-field v-model="address" readonly label="Address" ></v-text-field>
						</v-flex>
						<v-flex xs12 sm12 md4>
							<v-text-field v-model="TIN" readonly label="TIN" ></v-text-field>
						</v-flex>
						<v-flex md12>
							<v-text-field v-model="remarks" label="Remarks" :rules="default_rule"> </v-text-field>
						</v-flex>
					</v-layout>
					<v-layout>
						<v-flex xs12 sm12 md12>
							<h3>Attached Files:</h3>
							<v-layout column style="height: 100px">
								<v-flex style="overflow: auto" class="elevation-3">
									<v-data-table
										:headers="attachHeaders"
										:items="uploaded_file"
										:loading="false"
										class="elevation-1"
										:hide-default-footer="true"
										:hide-default-header="true"
										style="margin:0"
										:items-per-page="1000"
									>
										<template v-slot:items="props">
										<td>{{ props.item.title }}</td>					
										</template>
										<template v-slot:[`item.file`]="{ item }">
										<v-icon class="text-right mr-2" :color="item.seen ? 'gray' : 'primary'" medium @click="viewItem(item.path)"
											>mdi-eye-circle</v-icon
										>
										<v-icon class="text-right mr-2" color="red" medium @click="deleteItem(item)"
											>mdi-delete</v-icon
										>
										</template>
									</v-data-table>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-container>
				<div class="container">
					<div class="large-12 medium-12 small-12 cell">
						<h4>Supporting Documents</h4><br>
						<label>
							<input type="file" id="files" ref="files" multiple v-on:change="handleFileUploads()"/>
						</label>
					</div>
				</div>
				<v-container grid-list-md>
					<h4>Service Information</h4>
					<v-flex md12>
							<v-simple-table dense v-if="details.length !== 0">
								<template v-slot:default>
									<thead>
									<tr>
										<th>#</th>
										<th>Service</th>
										<th>Amount</th>
										<!-- <th>Action</th> -->
									</tr>
									</thead>
									<tbody>
									<tr
										v-for="(detail, detail_key) in details"
										:key="detail.detail_key"
									>
										<td>{{ detail_key + 1 }}</td>
										<td>
											<v-autocomplete
												v-model="detail.service_id"
												:items="service_data"
												editable
												item-text="billing_service_name"
												:rules="default_rule"
												item-value="id"
											></v-autocomplete>
										</td>
										<td>
											<v-text-field 
												v-model="detail.amount"
												type="text"
												:rules="default_rule"
												class="input_number text-right">
											</v-text-field>
										</td>
									</tr>
									<tr>
									<td colspan="2" style="text-align: right">
									<b>Total</b>
									</td>
									<td class="text-right">
										
									<b>{{total_amount}}</b>
									</td>
									</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-flex>
				</v-container>
				<v-container grid-list-md>
					<h4>Approving Officers</h4>
					<v-layout>
						<v-flex md6>
							<v-autocomplete
								v-model="dept_approver1"
								:items="getApprover(1)"
								editable
								item-text="approver_name"
								item-value="user_id"
								label="Immediate Supervisor"
								:rules="default_rule"
							></v-autocomplete>
						</v-flex>
						<v-flex md6>
								<v-autocomplete
									v-model="dept_approver2"
									:items="getApprover(2)"
									editable
									item-text="approver_name"
									item-value="user_id"
									label="Department Head"
									:rules="default_rule"
								></v-autocomplete>
						</v-flex>
					</v-layout>
				</v-container>
			</v-card-text>
			<div class="text-center">
				<v-dialog
					v-model="createDialog"
					persistent max-width="600"
				>
					<v-card>
						<v-card-title
							class="headline primary white--text"
							primary-title
						>
							Update Billing Request
						</v-card-title>
						<br>
						<v-card-text>
							Are you sure you want to submit the form?
						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								text
								@click="save()"
							>
								Save
							</v-btn>
							<v-btn
								color="red"
								text
								@click="createDialog = false"
							>
								Cancel
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
			<div class="text-center">
				<v-dialog
					v-model="cancelBillingRequest"
					persistent max-width="600"
				>
					<v-card>
						<v-card-title
							class="headline primary white--text"
							primary-title
						>
							Cancel Billing Request
						</v-card-title>
						<br>
						<v-card-text>
							Are you sure you want to cancel the form?
						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								text
								@click="cancelBilling()"
							>
								Save
							</v-btn>
							<v-btn
								color="red"
								text
								@click="cancelBillingRequest = false"
							>
								Cancel
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
			<v-card-actions>
				<v-spacer></v-spacer>
					<v-btn text color="blue accent-4" @click="createDialog = true">Update Billing Request</v-btn>
					<v-btn dark color="red accent-4" @click="cancelBillingRequest = true">Cancel Billing Request</v-btn>
			</v-card-actions>
		</v-card>
	</v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import numeral from 'numeral';
import jsonToFormData from 'json-form-data';

export default {
	props: {
		batchDetails: Array
	},
	data: () => ({
		upload_files: '',
		isvalid: true,
		module_types: [
			{code: 'F', name: 'File Maintenance'},
			{code: 'T', name: 'Transaction'},
			{code: 'R', name: 'Report'},
		],
		uploaded_file : [],
		details: [
			{service_id: '', amount: ''}
		],
		req_date: false,
		draftBilling : false,
		createDialog: false,
		cancelBillingRequest: false,
		payee_id: '',
		request_date: '',
		payment_term: 0,
		due_date: '',
		business_style: '',
		address: '',
		TIN: '',
		remarks: '',
		delete_file: [],
		service_data: [],
		dept_approver1: '',
		dept_approver2: '',
		total_amount: 0,
		atc_code: '',
		billingRequestNumber: '',
		deleted_files: [],
		default_rule: [
			v => !!v || 'Required'
		],
		attachHeaders: [
			{
				text: 'File Name',
				align: 'left',
				sortable: false,
				value: 'title'
			},
			{ 
				text: 'Actions',
				align: 'right',
				sortable: false,
				value: 'file',
			},
		],
		rate: 0
	}),

	computed: {
		...mapGetters({
			currUser: 'auth/currUser',
			editedItem: 'trxbilling/editedItem',
			formTitle: 'trxbilling/formTitle',
			valid: 'trxbilling/valid',
			dialog: 'trxbilling/dialog',
			editedIndex: 'trxbilling/editedIndex',
			payeefile: 'payefile/payefiles',
			billingDetails: 'trxbilling/billingDetails',
			services: 'services/services',
			businessType: 'payefile/businessType',
			alphataxcodes: 'alphataxcode/alphataxcodes',
			billingapprovingofficers: 'billingapprovingofficers/approvingofficers'
		}),
		payees(){
			let data = this.payeefile.filter(e => e.status === 1);
			return data;
		},
		filteredItems(){
			let payee = this.payees;
			let services = this.services;
			let filterPayee = [];
			
			services.forEach(detail => {
				let items = payee.filter((i)=>i.id === detail.payee_id);
				if(items.length > 0){
					items.forEach(pay => filterPayee.push(pay));
				}

			});
			return filterPayee;
		}
	},
	watch: {
		details: {
			handler(val){
				val.map(det => {
					det.amount = numeral(det.amount).format('0,0.[00]');
				});
				let total_amount = val.reduce((sum, details) => {
					if (details.amount === '') {
						details.amount = 0;
					}

					return sum + parseFloat(details.amount.replace(/,/g, ''));
				}, 0);
				this.total_amount = numeral(total_amount).format('0,0.[00]');
			},
			deep: true
		},
		billingDetails: {
			handler(val){
				this.payee_id = val[0].payee_id;
				this.request_date = val[0].request_date;
				this.payment_term = val[0].payment_term;
				this.due_date = val[0].due_date;
				this.business_style = val[0].business_style;
				this.address = val[0].address;
				this.TIN = val[0].TIN;
				this.remarks = val[0].remarks;
				this.service_data = val[0].service_data;
				this.dept_approver1 = val[0].dept_approver1;
				this.dept_approver2 = val[0].dept_approver2;
				this.total_amount = val[0].total_amount;
				this.billingRequestNumber = val[0].billing_request_no;
				this.total_amount = val[0].amount;
				this.uploaded_file = JSON.parse(val[0].upload_file);
				this.atc_code = val[0].atc_code;
				this.rate = val[0].tax_percent;

				let details = val[0].details.filter(e => e.remarks === 'Total Amount');
				let details2 = val[0].details.filter(e => e.remarks === 'Withholding Tax');
				this.details = [{
					service_id : details[0].billing_service_id,
					amount : parseFloat(details[0].debit_amount) + parseFloat(details2[0].debit_amount) != 0 ? parseFloat(details[0].debit_amount) + parseFloat(details2[0].debit_amount) : parseFloat(details[0].credit_amount)
				}];
				this.setDetails();

			}
		}
	},
	async mounted() {
		this.$store.dispatch('payefile/getPayefileDropdown');
		this.$store.dispatch('services/getServices');
		this.$store.dispatch('billingapprovingofficers/getApprovingOfficers');
		this.$store.dispatch('alphataxcode/getAlphataxcodeDropdown');

		this.request_date = format(new Date(), 'yyyy-MM-dd');
		this.service_data = [];
		this.$store.dispatch('trxbilling/getDetails', this.batchDetails.billing_request_no);
	},

	methods: {
		close() {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('trxbilling/setValid', false);
				this.$store.dispatch('trxbilling/setEditDialog', false);
				this.$store.dispatch('trxbilling/setEditedIndex', -1);
				this.isvalid = false;
			}, 2000);
		},
		deleteItem(item){
			this.uploaded_file.splice(this.uploaded_file.indexOf(item), 1);
			this.deleted_files.push(item.filename);
		},
		async save() {
			this.$store.dispatch('trxbilling/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			let headers = [];
			let details = [];
			let total_amount = 0;
			this.details.forEach(det => {
				let amount = parseFloat(det.amount.replace(/,/g, ''));
				let service = this.services.filter(serv => serv.id === det.service_id);
				if(service.length > 0){
					service[0]['billingentries'].forEach(ent => {
						let debit_amount = 0;
						let credit_amount = 0;
						let remarks = '';
						let vat = 0;
						if(ent.account_flag === 2){
							remarks = 'Income Amount';
							if(service[0].vat_category === 1){
								vat = (amount / 1.12) * (12 / 100);
								credit_amount = amount - vat;
							} else { credit_amount = amount; }
						} else if (ent.account_flag === 3 && service[0].vat_category !== 3){
							remarks = 'Vat Amount';
							if(service[0].vat_category === 1){
								credit_amount = (amount / 1.12) * (12 / 100);
							} else if (service[0].vat_category === 2) {
								vat = amount * (12 / 100);
								credit_amount = vat;
							}
						} else if (ent.account_flag === 1){
							remarks = 'Total Amount';
							if(service[0].vat_category === 1) {
								debit_amount = (amount - ((amount / 1.12) * (this.rate/100))) ;
							} else if(service[0].vat_category === 2) {
								debit_amount = (amount + (amount * (12 / 100))) - ((amount) * (this.rate/100)) ;
							} else { debit_amount = amount - (amount * (this.rate/100)); }
							
							total_amount = total_amount + debit_amount;
						} else if (ent.account_flag === 4){
							remarks = 'Withholding Tax';
							if(service[0].vat_category === 1) {
								debit_amount = (amount / 1.12) * (this.rate/100);
							} else if(service[0].vat_category === 2) {
								debit_amount = amount * (this.rate/100);
							} else { debit_amount = amount * (this.rate/100); }
							
							total_amount = total_amount + debit_amount;
						}
						details.push(
							{
								'billing_request_no': this.billingRequestNumber,
								'payee_id': this.payee_id,
								'department_id': this.currUser.dept_id,
								'branch_id': this.currUser.branch_id,
								'section_id': 0,
								'account_code': ent.account_code,
								'billing_service_id': ent.billing_service_id,
								'debit_amount': parseFloat(debit_amount.toFixed(2)),
								'credit_amount': parseFloat(credit_amount.toFixed(2)),
								'edit_by': this.currUser.id,
								'status': 3,
								'remarks': remarks
							}
						);
					});
				}
			});
			
			let header = {
				'billing_request_no': this.billingRequestNumber,
				'request_date': this.request_date,
				'payee_id': this.payee_id,
				'amount': total_amount,
				'request_status': 4,
				'department_id': this.currUser.dept_id,
				'branch_id': this.currUser.branch_id,
				'section_id': 0,
				'dept_approver1': this.dept_approver1,
				'dept_approver2': this.dept_approver2,
				'remarks': this.remarks,
				'edit_by': this.currUser.id
			};

			headers.push(header);
			let data = {};
			data.headers = headers;
			data.details = details;
			data.image_title = [];
			data.upload_file = [];

			for( var i = 0; i < this.upload_files.length; i++ ){
				let file = this.upload_files[i];
				data.image_title.push(file.name);
				data.upload_file.push(file);
			}

			
			data.delete_file = this.delete_file;
			if(this.deleted_files.length > 0){
				for( var j = 0; j < this.deleted_files.length; j++ ){
					data.delete_file.push(this.deleted_files[j]);
				}
			}
			data._method = 'PUT';
			data.draft = 1;
			var options = {
				initialFormData: new FormData(),
				showLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};

			var convertedFormData = jsonToFormData(data, options);
			if(this.isvalid){
				this.$store.dispatch('trxbilling/updateBilling',{id: this.billingRequestNumber, value:convertedFormData});
			} else {
				this.createDialog = false;
				this.$store.commit('app/SET_SNACK_BAR', { 
					'status': true, 
					'message': 'Please fill out all the required fields.', 
					'messagetype': 'error' 
				}, { root: true });
			}
		},
		async setDetails(){
			await this.$store.dispatch('payefile/getPayeeDet', this.payee_id).then(response => {
				let data = response.data.data;
				this.address = data[0].house_unit + ' ' + data[0].street + ' ' + data[0].barangay + ' ' + data[0].municipality + ' ' + data[0].city; 
				this.business_style = data[0].business_style;
				this.TIN = data[0].tin;
				this.rate = data[0].tax_percent;
			});
			this.service_data = this.services.filter(det => det.payee_id === this.payee_id);
			
		},
		getApprover(data){
			let officers = [];
			if( this.billingapprovingofficers.data.length !== 0){
				officers = this.billingapprovingofficers.data.filter(det => det.level === data);
			}
			return officers;
		},
		viewItem(item) {
			//let url = process.env.VUE_APP_STORAGE_URL + '/' + this.billingDetails[0].billing_invoice_no + '/' + item;
			let path = atob(item);
			fetch(path)
				.then(res => res.blob()) // Gets the response and returns it as a blob
				.then(blob => {
					const objectURL = URL.createObjectURL(blob);
					window.open(objectURL, '_blank');
				});
		},
		handleFileUploads(){
			this.upload_files = this.$refs.files.files;
		},
		addService(){
			let det = {service_id: '', amount: ''};
			this.details.push(det);
		},
		deleteService(key, detail) {
			this.details.filter((detail, index) => {
				if (index === key) {
					this.details.splice(key, 1);
				}
			});

			if (detail.id) {
				this.deleted.push({ id: detail.id });
			}
		},
		cancelBilling() {
			let data = {};
			data.request_status = 20;
			data.billing_request_no = this.batchDetails.billing_request_no;
			data.approved_by = this.currUser.id;
			data._method = 'PUT';
			data.update_category = 'cancel_billing';
			this.$store.dispatch('trxbilling/updateBillingStatus', {id: this.batchDetails.billing_request_no, value: data});
		},
	}
};
</script>
<style scoped>
  .remove-underline.v-text-field .v-input__control .v-input__slot:before {
    border-style: none;
  }
  .remove-underline.v-text-field .v-input__control .v-input__slot:after {
    border-style: none;
  }

  /*Remove spinners*/
  .input_number input[type='number'] {
    -moz-appearance: textfield;
    text-align: right;
  }

  .input_number input::-webkit-outer-spin-button,
  .input_number input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

	.document-card-container.v-card--outlined,
	.document-form-container.v-card--outlined {
		-webkit-box-shadow: none !important;
    box-shadow: none !important;
	}

	.document-card-title {
		padding: 0;
	}

	.document-form-container.v-card--outlined {
		border: 0;
	}
</style>
