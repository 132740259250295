<template>
  <v-card>
	<v-card-title
      class="headline primary dark white--text"
      primary-title
    >
      Billing Request No. {{billingDetails[0].billing_request_no}} 
      <v-spacer/>
      <v-btn icon dark @click="closeDialog()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
	<v-card-text>
      <br>
	<v-layout v-if="billingDetails[0].reject_remarks">
        <v-flex xs12 sm12 md12>
			<v-alert type="error" outlined>
				This Billing has been rejected: Reason - {{billingDetails[0].reject_remarks}}
			</v-alert>
		</v-flex>
	</v-layout>
	<v-layout>
        <v-flex xs12 sm12 md4>
			<h2>Billing Information:</h2>
			<br>
			<h3 style="color: red" v-if="billingDetails[0].accounting_status === 20"> Void Transaction</h3>
			<h3>Billing Request No: {{billingDetails[0].billing_request_no}}</h3>
			<h3>Billing Invoice No: {{billingDetails[0].billing_invoice_no}}</h3>
			<h3>Customer: {{getValue(payees, "id", billingDetails[0].payee_id, "payee_desc")}}</h3>
			<h3>Request Date: {{getDateFormat(billingDetails[0].request_date)}}</h3>
			<h3 v-if="billingDetails[0].accounting_status !== 0">Billing Date: {{getDateFormat(billingDetails[0].billing_date)}}</h3>
			<h3 v-if="billingDetails[0].accounting_status !== 0">Due Date: {{getDateFormat(billingDetails[0].due_date)}}</h3>
			<h3 v-if="billingDetails[0].accounting_status !== 0">Term: {{billingDetails[0].payment_term === 0 ? 'Due Immediately': billingDetails[0].payment_term}}</h3>
			<h3>Remarks: {{billingDetails[0].remarks}}</h3>
		</v-flex>
		<v-spacer/>
        <v-flex xs12 sm12 md6>
          <h3>Attached Files:</h3>
          <v-layout column style="height: 100px">
            <v-flex style="overflow: auto" class="elevation-3">
              <v-data-table
                :headers="attachHeaders"
                :items="billingDetailed"
                :loading="false"
                class="elevation-1"
                :hide-default-footer="true"
                :hide-default-header="true"
                style="margin:0"
				:items-per-page="1000"
              >
                <template v-slot:items="props">
                  <td>{{ props.item.title }}</td>					
                </template>
                <template v-slot:[`item.file`]="{ item }">
                  <v-icon class="text-right mr-2" :color="item.seen ? 'gray' : 'primary'" medium @click="viewItem(item.path)"
                    >mdi-eye-circle</v-icon
                  >
                </template>
              </v-data-table>
            </v-flex>
		</v-layout>
		</v-flex>
	</v-layout>
	<br>
	<h2 v-if="(billingDetails[0].request_status === 7 && billingDetails[0].accounting_status === 0) || (billingDetails[0].request_status === 7 && billingDetails[0].accounting_status === 10)">Payment Information</h2>
	<v-container grid-list-md  v-if="billingDetails[0].request_status === 7 && (billingDetails[0].accounting_status === 0 || billingDetails[0].accounting_status === 10 )">
		<v-layout wrap>
			<v-flex xs12 sm12 md3>
				<v-text-field v-if="billingDetails[0].accounting_status === 0" v-model="billingNumber" readonly label="Billing Invoice"></v-text-field>
				<v-text-field v-else v-model="billingDetails[0].billing_invoice_no" readonly label="Billing Invoice"></v-text-field>
				</v-flex>
			<v-flex xs12 sm12 md3>
				<v-text-field v-model="billing_date" readonly label="Billing Date"></v-text-field>
				</v-flex>
			<v-flex xs12 sm12 md3>
				<v-text-field v-model="payment_term" type="number" label="Payment Term (days)"></v-text-field>
			</v-flex>
			<v-flex xs12 sm12 md3>
				<v-text-field v-model="due_date" readonly label="Due Date"></v-text-field>
			</v-flex>
			
		</v-layout>
	</v-container>
	<br><h2>Services</h2>
		<v-container class="doc-items-container" grid-list-md >
			<v-form ref="form" v-model="isvalid" lazy-validation>
				<v-simple-table dense>
					<template v-slot:default v-if="billingDetails[0].request_status !== 7">
						<thead>
						<tr class="text-center">
							<th>#</th>
							<th>Services</th>
							<th>Description</th>
							<th style="text-align:center">Amount</th>
						</tr>
						</thead>
							<tbody>
						<tr
							v-for="(detail, detail_key) in getServiceDetail(billingDetails[0].details)"
							:key="detail.detail_key"
						>
							<td>{{ detail_key + 1 }}</td>
							<td>
							{{ getService(detail.billing_service_id, billingDetails[0].payee_id) }}
							</td>
							<td>
							{{ detail.remarks }}
							</td>
							<td class="text-right">
							{{formatAmount(detail.credit_amount !== '0' ? detail.credit_amount : detail.debit_amount)}}
							</td>
						</tr>
						<!-- <tr>
							<td colspan="3" class="text-right">
							<b>Total              </b>
							</td>
							<td class="text-right">
							<b>{{formatAmount(billingDetails[0].amount)}}</b>
							</td>
						</tr> -->
						</tbody>
					</template>
						<template v-slot:default v-else-if="billingDetails[0].request_status === 7 && billingDetails[0].accounting_status === 0">
						<thead>
							<tr>
								<th>#</th>
								<th>Department</th>
								<th>Branch</th>
								<th>Section</th>
								<th width="30%">Chart of Accounts</th>
								<th>Debit</th>
								<th>Credit</th>
								<th>Delete</th>
							</tr>
							</thead>
							<tbody>
							<tr
								v-for="(detail, detail_key) in billingDetails[0].details"
								:key="detail.detail_key"
							>
								<td>{{ detail_key + 1}}</td>
								<td>
								<v-autocomplete
									class="remove-underline"
									v-model="detail.department_id"
									:items="dataDepts"
									editable
									hide-details
									:rules="default_rule"
									item-text="dept_desc"
									item-value="id"
									@change="getDeptData(detail.department_id, detail_key)"
								>
								</v-autocomplete>
								</td>
								<td>
								<v-autocomplete
									class="remove-underline"
									v-model="detail.branch_id"
									:items="branches"
									:rules="default_rule"
									editable
									hide-details
									item-text="code_name"
									item-value="id"
									v-on:focus="getDeptData(detail.department_id, detail_key)"
								></v-autocomplete>
								</td>
								<td>
								<v-autocomplete
									class="remove-underline"
									v-model="detail.section_id"
									:items="detail.sections"
									:rules="detail.sections ? (detail.sections.length > 0 ? default_rule : []) : []"
									v-on:focus="getDeptData(detail.department_id, detail_key)"
									editable
									hide-details
									item-text="section_name"
									item-value="section_id"
								></v-autocomplete>
								</td>
								<td>
								<v-autocomplete
									class="remove-underline"
									v-model="detail.account_code"
									:items="coaData"
									:rules="default_rule"
									editable
									hide-details
									item-text="code_name"
									item-value="acct_code"
								></v-autocomplete>
								</td>
								<td>
								<v-text-field
									v-model="detail.debit_amount"
									:readonly="parseFloat((typeof detail.credit_amount ==='string' ? detail.credit_amount.replace(/,/g, '') : detail.credit_amount)) > 0 || detail.remarks === 'Tax Based Amount' || detail.remarks === 'Outright Amount'"
									type="text"
									class="input_number text-right amount"
									editable
									hide-details
								></v-text-field>
								</td>
								<td>
								<v-text-field
									v-model="detail.credit_amount"
									:readonly="parseFloat((typeof detail.debit_amount ==='string' ? detail.debit_amount.replace(/,/g, '') : detail.debit_amount)) > 0"
									type="text"
									class="input_number text-right amount"
									editable
									hide-details
								></v-text-field>
								</td>
								<td>
								<v-btn icon @click="removeDetail(detail_key)">
									<v-icon small color="red">delete</v-icon>
								</v-btn>
								</td>
							</tr>
							<tr>
								<td class="text-right" colspan="5">
								<b>TOTAL </b>
								</td>
								<td class="text-right">
								<b>{{billingDetails[0].total_debit_amount}}</b>
								</td>
								<td class="text-right">
								<b>{{billingDetails[0].total_credit_amount}}</b>
								</td>
								<td>&nbsp;</td>
							</tr>
							</tbody>
					</template>
					<template v-slot:default v-else>
						<thead>
						<tr class="text-center">
							<th>#</th>
							<th>Services</th>
							<th>Account Code</th>
							<th>Department</th>
							<th>Branch</th>
							<th>Section</th>
							<th style="text-align:center">Debit Amount</th>
							<th style="text-align:center">Credit Amount</th>
						</tr>
						</thead>
							<tbody>
						<tr
							v-for="(detail, detail_key) in billingDetails[0].details"
							:key="detail.detail_key"
						>
							<td>{{ detail_key + 1 }}</td>
							<td>
							{{ getService(detail.billing_service_id, billingDetails[0].payee_id) }}
							</td>
							<td>
							{{ getValue(coaData, "acct_code", detail.account_code, "acct_desc") }}
							</td>
							<td>
							{{ getValue(dataDepts, 'id', detail.department_id, "dept_code") }}
							</td>
							<td>
								{{ getValue(branches, 'id', detail.branch_id, "brnc_code") }}
							</td>
							<td>
							{{ getValue(dataSections,'id',detail.section_id, "section_code" )}}
							</td>
							<td class="text-right">
							{{formatAmount(detail.debit_amount)}}
							</td>
							<td class="text-right">
							{{formatAmount(detail.credit_amount)}}
							</td>
						</tr>
						<tr>
							<td colspan="6" class="text-right">
							<b>Total</b>
							</td>
							<td class="text-right">
							<b>{{formatAmount(billingDetails[0].total_debit_amount)}}</b>
							</td>
							<td class="text-right">
							<b>{{formatAmount(billingDetails[0].total_credit_amount)}}</b>
							</td>
						</tr>
						</tbody>
					</template>
					</v-simple-table>
					<v-btn right dark color="primary" @click="addEntry" v-if="billingDetails[0].request_status === 7 && billingDetails[0].accounting_status === 0">
							<v-icon>mdi-plus</v-icon>
							<span>Add Entry</span>
					</v-btn>
					<v-layout>
						<v-flex md6>
							<h4>Requestor: {{getUser(billingDetails[0].edit_by)}}</h4>
							<h4>Immediate Supervisor: {{getUser(billingDetails[0].dept_approver1)}}</h4>
							<h4>Department Head: {{getUser(billingDetails[0].dept_approver2)}}</h4>
						</v-flex>
						<v-flex md6>
							<h4 v-if="billingDetails[0].accounting_status !== 0">Billing Preparer: {{getUser(billingDetails[0].prepared_by)}}</h4>
							<h4 v-if="billingDetails[0].accounting_status > 3">Approved By: {{getUser(billingDetails[0].accounting_approved_by)}}</h4>
						</v-flex>
					</v-layout>
			</v-form>
		</v-container>
	</v-card-text>
	<v-card-actions v-if="billingDetails[0].accounting_status === 0 || billingDetails[0].accounting_status === 10">
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        text
        @click="approveBilling = true"
      >
      Approve
      </v-btn>
      <v-btn
        color="red"
        text
        @click="rejectBilling = true"
      >
      Reject
      </v-btn>
    </v-card-actions>
	<v-card-actions v-if="billingDetails[0].accounting_status===3 && parseFloat(currUser.max_amount) > parseFloat(level1.min_amount)"><!-- && parseFloat(currUser.max_amount) < parseFloat(level1.max_amount) + 1-->
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        @click="extendDue = true"
      >
      Extend Due Date
      </v-btn>
      <v-btn
        color="success"
        text
        @click="approveBilling = true"
      >
      Approve
      </v-btn>
      <v-btn
        color="red"
        text
        @click="rejectBilling = true"
      >
      Reject
      </v-btn>
    </v-card-actions>
	<v-card-actions v-if="billingDetails[0].accounting_status===4 && parseFloat(currUser.max_amount) > parseFloat(level2.min_amount)"><!-- && parseFloat(currUser.max_amount) < parseFloat(level2.max_amount) + 1-->
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        text
        @click="approveBilling = true"
      >
      Approve
      </v-btn>
      <v-btn
        color="red"
        text
        @click="rejectBilling = true"
      >
      Reject
      </v-btn>
    </v-card-actions>
	<v-card-actions v-if="billingDetails[0].accounting_status===5 && parseFloat(currUser.max_amount) > parseFloat(level2.max_amount)">
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        text
        @click="approveBilling = true"
      >
      Approve
      </v-btn>
      <v-btn
        color="red"
        text
        @click="rejectBilling = true"
      >
      Reject
      </v-btn>
    </v-card-actions>
    <div class="text-center">
      <v-dialog
        v-model="approveBilling"
        persistent max-width="600"
      >
        <v-card>
          <v-card-title
            class="headline primary white--text"
            primary-title
          >
            Approve Billing
          </v-card-title>
          <br>
          <v-card-text>
            Are you sure you want to Approve this Billing with Invoice No. {{billingDetails[0].billing_invoice_no}} ?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="approve(billingDetails[0].billing_request_no, (billingDetails[0].accounting_status === 0 ? billingDetails[0].request_status : billingDetails[0].accounting_status), (billingDetails[0].accounting_status === 0 ? 'request_status' : 'accounting_status' ), billingDetails[0].amount)"
            >
              Approve
            </v-btn>
            <v-btn
              color="red"
              text
              @click="approveBilling = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
	<div class="text-center">
      <v-dialog
        v-model="extendDue"
        persistent max-width="800"
      >
        <v-card>
          <v-card-title
            class="headline primary white--text"
            primary-title
          >
            Due Date Extension
          </v-card-title>
          <br>
          <v-card-text>
            Please input due date extension:
          </v-card-text>
			<v-container grid-list-md>
				<v-layout wrap>
					<v-flex xs12 sm12 md12>
						<v-menu
							v-model="due_extension"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							min-width="290px"
							:close-on-content-click="false"
							>
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="due_date_extension"
									required
									:rules="date_rules"
									return-masked-value
									v-on="on"
									mask="YYYY-MM-DD"
									placeholder="YYYY-MM-DD">
								</v-text-field>
							</template>
							<v-date-picker
								color="primary"
								v-model="due_date_extension"
								@change="due_extension = false;"
							></v-date-picker>
						</v-menu>
					</v-flex>
				</v-layout>
			</v-container>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="extendDueDate(billingDetails[0].billing_request_no)"
            >
              Extend Due Date
            </v-btn>
            <v-btn
              color="red"
              text
              @click="approveBilling = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
	<div class="text-center">
      <v-dialog
        v-model="rejectBillingReason"
        persistent max-width="600"
      >
        <v-card>
          <v-card-title
            class="headline primary white--text"
            primary-title
          >
            Reason for Rejection
          </v-card-title>
          <br>
          <v-card-text>
            Please state the reason:
            <v-text-field v-model="rejectRemarks" label="Remarks" required></v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="reject(billingDetails[0].billing_request_no, (billingDetails[0].request_status !== 7 ? billingDetails[0].request_status : billingDetails[0].accounting_status), (billingDetails[0].request_status !== 7 ? 'request_status' : 'accounting_status' ),billingDetails[0].billing_invoice_no)"
            >
              Reject
            </v-btn>
            <v-btn
              color="red"
              text
              @click="rejectDisbursementReason = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog
        v-model="rejectBilling"
        persistent max-width="600"
      >
        <v-card>
          <v-card-title
            class="headline primary white--text"
            primary-title
          >
            Reject Billing
          </v-card-title>
          <br>
          <v-card-text>
            Are you sure you want to reject this Billing with Invoice No. {{billingDetails[0].billing_invoice_no}} ?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="rejectBillingReason = true; rejectBilling = false"
            >
              Reject
            </v-btn>
            <v-btn
              color="red"
              text
              @click="rejectBilling = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar
      v-model="snackbar"
      right
    >
      {{ error_message }}
      <v-btn
        color="pink"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import jsonToFormData from 'json-form-data';
import { getMonth, getYear, parseISO, format, addDays } from 'date-fns';
export default {
	props: {
		batchDetails: Object,
		documentDetails: Object
	},
	data() {
		return {
			isvalid: true,
			level1: {},
			level2: {},
			level3: {},
			upload_files: '',
			upload_file: [],
			image_title: [],
			delete_file: [],
			seenAttachedFile:[],
			dataBranch: [],
			dataAtc: [],
			due_date_extension: '',
			due_extension: false,
			// dataPayees: [],
			dataDepts: [],
			dataSections: [],
			dataUsers: [],
			dataDollarRate: [],
			nextPageDollarRate: '',
			dataRegularTrx: [],
			nextPageRegularTrx: '',
			nextPageSections: '',
			nextPageUsers: '',
			nextPageBranch: '',
			nextPageAtc: '',
			payment_term: 0,
			due_date: '',
			billing_date: '',
			bill_date: false,
			rejectBilling: false,
			rejectBillingReason: false,
			rejectRemarks: '',
			// nextPagePayees: '',
			nextPageDepts: '',
			attachHeaders: [
				{
					text: 'File Name',
					align: 'left',
					sortable: false,
					value: 'title'
				},
				{ 
					text: 'Actions',
					align: 'right',
					sortable: false,
					value: 'file',
				},
			],
			panel: 0,
			detailHeaders:[
				{ text: 'Analysis', value: 'payee_id' },
				{ text: 'Department', value: 'department_id' },
				{ text: 'Branch', value: 'branch_id' },
				{ text: 'Section', value: 'section_id' },
				{ text: 'Chart of Accounts', value: 'account_code' },
				{ text: 'Debit', value: 'debit_amount' },
				{ text: 'Credit', value: 'credit_amount' },
			],
			error_message: 'Please view attached file before approving.',
			snackbar: false,
			approveBilling: false,
			extendDue: false,
			partner_bank_files: [],
			batch_number_rules: [
				v => !!v || 'Batch Number is required',
			],
			date_rules: [
				v => !!v || 'Date is required',
			],
			default_rule: [
				v => !!v || 'Required',
			],
			payee_rules: [
				v => !!v || 'Payor is required',
			],
			bank_rules: [
				v => !!v || 'Bank is required',
			]
		};
	},
	computed: {
		...mapGetters({
			services: 'services/services',
			depts: 'dept/depts',
			branches: 'branch/branches',
			sections: 'section/sections',
			payees: 'payefile/payefiles',
			alphataxcodes: 'alphataxcode/alphataxcodes',
			headers: 'trxbilling/headers',
			currUser: 'auth/currUser',
			approvingofficers: 'approvingofficer/approvingofficers',
			viewDialog: 'trxbilling/viewDialog',
			regulartrx: 'regularTxns/regulartxns',
			preparer: 'trxbilling/preparer',
			billingDetails: 'trxbilling/billingDetails',
			billingDetailed: 'trxbilling/billingDetailed',
			dollarrates: 'dollarrate/dollarrates',
			currencies: 'currency/currencies',
			approvinglevel: 'approvinglevel/approvinglevels',
			coaData: 'coa/coaData',
			billingNumber: 'trxbilling/billingNumber',
			users: 'user/users'
		}),
	},
	watch: {
		details: {
			handler(val){
				val.map(det => {
					det.debit_amount = numeral(det.debit_amount).format('0,0.[00]');
					det.credit_amount = numeral(det.credit_amount).format('0,0.[00]');
				});
				let overallDebit = val.reduce((sum, details) => {
					if (details.debit_amount === '') {
						details.debit_amount = 0;
					}

					return sum + parseFloat(details.debit_amount.replace(/,/g, ''));
				}, 0);
				this.overallDebit = numeral(overallDebit).format('0,0.[00]');
				let overallCredit = val.reduce((sum, details) => {
					if (details.credit_amount === '') {
						details.credit_amount = 0;
					}

					return sum + parseFloat(details.credit_amount.replace(/,/g, ''));
				}, 0);
				this.overallCredit = numeral(overallCredit).format('0,0.[00]');
			},
			deep: true
		},
		approvinglevel: {
			handler(val) {
				val.forEach(level => {
					if (level.level_code == 1){
						this.level1 = {'min_amount': parseFloat(level.min_amount),'max_amount': parseFloat(level.max_amount)};
					} else if (level.level_code == 2){
						this.level2 = {'min_amount': parseFloat(level.min_amount),'max_amount': parseFloat(level.max_amount)};
					} else if (level.level_code == 3){
						this.level3 = {'min_amount': parseFloat(level.min_amount),'max_amount': parseFloat(level.max_amount)};
					}
				});
			}
		},
		batchDetails: {
			handler(val) {
				this.$emit('update:batchDetails', val);
			},
			deep: true
		},
		documentDetails: {
			handler(val) {
				this.$emit('update:documentDetails', val);
			},
			deep: true
		},
		depts: {
			handler() {
				this.getDataDepts();
			}
		},
		alphataxcodes: {
			handler() {
				this.getDataAlphataxcodes();
			}
		},
		sections: {
			handler() {
				this.getDataSections();
			}
		},
		users: {
			handler() {
				this.getDataUsers();
			}
		},
		dollarrates: {
			handler() {
				this.getDataDollarRate();
			}
		},
		regulartrx: {
			handler() {
				this.getDataRegularTrx();
			}
		},
		billingDetails: {
			handler(val){
				val[0].details.map(det => {
					det.debit_amount = numeral(det.debit_amount).format('0,0.[00]');
					det.credit_amount = numeral(det.credit_amount).format('0,0.[00]');
				});
				let total_debit_amount = val[0].details.reduce((sum, details) => {
					if (details.debit_amount === '') {
						details.debit_amount = 0;
					}

					return sum + parseFloat(details.debit_amount.replace(/,/g, ''));
				}, 0);
				let total_credit_amount = val[0].details.reduce((sum, details) => {
					if (details.credit_amount === '') {
						details.credit_amount = 0;
					}

					return sum + parseFloat(details.credit_amount.replace(/,/g, ''));
				}, 0);
				val[0].total_debit_amount = numeral(total_debit_amount).format('0,0.[00]');
				val[0].total_credit_amount = numeral(total_credit_amount).format('0,0.[00]');
				
				if(val[0].billing_date){
					this.billing_date = this.getDateFormat(val[0].billing_date);
					this.due_date = this.getDateFormat(val[0].due_date);
					this.payment_term = val[0].payment_term;
				}
			},
			deep: true
		},
		payment_term: {
			handler(val){
				this.computeDueDate(val);
			}
		},
		billing_date: {
			handler(){
				this.computeDueDate(this.payment_term);
			}
		},
	},
	mounted() {
		this.$store.dispatch('services/getServices');
		this.$store.dispatch('approvinglevel/getApprovingLevels');
		this.$store.dispatch('dept/getDepts');
		this.$store.dispatch('branch/getBranches');
		this.$store.dispatch('section/getSections');
		this.$store.dispatch('payefile/getPayefiles');
		this.$store.dispatch('alphataxcode/getAlphataxcodes');
		this.$store.dispatch('regularTxns/getregularTxns');
		this.$store.dispatch('approvingofficer/getApprovingOfficers');
		this.$store.dispatch('dollarrate/getDollarrates');
		this.$store.dispatch('currency/getCurrencies');
		this.$store.dispatch('user/getUsers');
		
		this.billing_date = format(new Date(), 'yyyy-MM-dd');
		this.due_date = format(new Date(), 'yyyy-MM-dd');
		this.billingDetails[0].details.map(det => {
			det.debit_amount = numeral(det.debit_amount).format('0,0.[00]');
			det.credit_amount = numeral(det.credit_amount).format('0,0.[00]');
		});
		let total_debit_amount = this.billingDetails[0].details.reduce((sum, details) => {
			if (details.debit_amount === '') {
				details.debit_amount = 0;
			}

			return sum + parseFloat(details.debit_amount.replace(/,/g, ''));
		}, 0);
		let total_credit_amount = this.billingDetails[0].details.reduce((sum, details) => {
			if (details.credit_amount === '') {
				details.credit_amount = 0;
			}

			return sum + parseFloat(details.credit_amount.replace(/,/g, ''));
		}, 0);
		this.billingDetails[0].total_debit_amount = numeral(total_debit_amount).format('0,0.[00]');
		this.billingDetails[0].total_credit_amount = numeral(total_credit_amount).format('0,0.[00]');
	},
	methods: {
		computeDueDate(days){
			this.due_date = format(addDays(new Date(this.billing_date), days), 'yyyy-MM-dd');
		},
		approve(billing_request, current_status, status_type, amount) {
			this.isvalid = this.$refs.form.validate();
			if(this.isvalid){
				let attachIsClicked = true;
				let attachment = [];
				let detail = [];
				this.error_message = '';

				this.billingDetailed.forEach(i => {
					attachment.push(i.path);
				});
				attachment.map(i => {
					let detailData = {document_number: i, matched: this.seenAttachedFile.includes(i)};
					detail.push(detailData);
				});

				detail.forEach(details => {
					if(!details.matched){
						attachIsClicked = false;
					}
				});

				if(attachIsClicked){
					let data = {};
					let newStatus = 3;
					switch(current_status){
					case 3:
						newStatus = 4;
						break;
					case 4:
						newStatus = 5;
						break;
					case 5:
						newStatus = 7;
						break;
							
					}
					if(status_type === 'request_status' && (current_status !== 7 && current_status !== 10)){
						data.request_status = newStatus;
						data.billing_request_no = billing_request;
						data.approved_by = this.currUser.id;
						data._method = 'PUT';
						data.update_category = 'request_status';
						this.$store.dispatch('trxbilling/updateBillingStatus', {id: billing_request, value: data});
					}else{
						if((status_type === 'request_status' && current_status === 7) || (status_type === 'accounting_status' && current_status === 10)){
							let header = [];
							let details = [];
							let total_debit_amount = 0;
							let total_credit_amount = 0;
							let billing_number = this.billingNumber;
							if(current_status === 10) {
								billing_number = this.billingDetails[0].billing_invoice_no;
							}
							
							this.billingDetails[0].details.forEach(det => {
								details.push({
									'billing_invoice_no': billing_number,
									'billing_date': this.billing_date,
									'billing_request_no': billing_request, 
									'payee_id': det.payee_id,
									'department_id': det.department_id,
									'branch_id': det.branch_id,
									'section_id': det.section_id,
									'account_code': det.account_code,
									'billing_service_id': det.billing_service_id,
									'debit_amount': parseFloat(det.debit_amount.replace(/,/g, '')),
									'credit_amount': parseFloat(det.credit_amount.replace(/,/g, '')),
									'edit_by': this.currUser.id,
									'status': 3,
									'remarks': det.remarks
								});
								
								if(det.debit_amount !== 0){
									total_debit_amount = total_debit_amount + parseFloat(det.debit_amount.replace(/,/g, ''));
								}
								if(det.credit_amount !== 0){
									total_credit_amount = total_credit_amount + parseFloat(det.credit_amount.replace(/,/g, ''));
								}
							});

							header.push({
								'billing_request_no': billing_request, 
								'billing_invoice_no': billing_number,
								'billing_date': this.billing_date,
								'request_date': this.billingDetails[0].request_date,
								'payee_id': this.billingDetails[0].payee_id,
								'dept_approver1': this.billingDetails[0].dept_approver1,
								'dept_approver2': this.billingDetails[0].dept_approver2,
								'payment_term': this.payment_term,
								'due_date': this.due_date,
								'request_status': 7,
								'accounting_status': 3,
								'amount': total_debit_amount,
								'remarks': this.billingDetails[0].remarks,
								'prepared_by': this.currUser.id,
								'edit_by': this.currUser.id
							});


							let data = {};
							data.headers = header;
							data.details = details;
							data.image_title = [];
							data.upload_file = [];
							data.draft = 1;
							data._method = 'PUT';

							for( var i = 0; i < this.upload_files.length; i++ ){
								let file = this.upload_files[i];
								data.image_title.push(file.name);
								data.upload_file.push(file);
							}

							var options = {
								initialFormData: new FormData(),
								showLeafArrayIndexes: true,
								includeNullValues: false,
								mapping: function(value) {
									if (typeof value === 'boolean') {
										return +value ? '1': '0';
									}
									return value;
								}
							};

							var convertedFormData = jsonToFormData(data, options);
							if(numeral(total_debit_amount).format('0,0.[00]') != numeral(total_credit_amount).format('0,0.[00]')){
								this.approveBilling = false;
								this.$store.commit('app/SET_SNACK_BAR', { 
									'status': true, 
									'message': 'Unbalance amount.', 
									'messagetype': 'error' 
								}, { root: true });
							} else {
								this.$store.dispatch('trxbilling/updateBilling',{id: billing_request, value:convertedFormData});
							}
						} else {
							data.billing_request_no = billing_request;
							data.billing_invoice_no = this.billingDetails[0].billing_invoice_no;
							data.accounting_approved_by = this.currUser.id;
							data._method = 'PUT';
							data.update_category = 'accounting_status';
							if(current_status === 3){
								if(parseFloat(amount) > parseFloat(this.currUser.max_amount) && parseFloat(this.currUser.max_amount) === parseFloat(this.level1.max_amount)){
									data.accounting_status = 4;
								} else if (parseFloat(amount) > parseFloat(this.currUser.max_amount) && parseFloat(this.currUser.max_amount) === parseFloat(this.level2.max_amount)){
									data.accounting_status = 5;
								}else {
									data.accounting_status = 7;
								}
							} else if ( current_status === 4 ){
								if(parseFloat(amount) > parseFloat(this.currUser.max_amount) && parseFloat(this.currUser.max_amount) === parseFloat(this.level2.max_amount)){
									data.accounting_status = 5;
								} else {
									data.accounting_status = 7;
								}
							} else if ( current_status === 5 ){
								if( parseFloat(amount) <= parseFloat(this.currUser.max_amount)){
									data.accounting_status = 7;
								}
							}
							this.$store.dispatch('trxbilling/updateBillingAccountingStatus', {id: billing_request, value: data});
						}
					}
				} else {
					this.error_message = 'Please view attached file before approving.';
					this.approveBilling = false;
					this.snackbar = true;
				}
			} else {
				this.approveBilling = false;
				this.$store.commit('app/SET_SNACK_BAR', { 
					'status': true, 
					'message': 'Please fill out all the required fields.', 
					'messagetype': 'error' 
				}, { root: true });
			}
		},
		approveProps(current_user_name, totalAmount, amountThreshold, current_status, moveToStatus, options, data, filterByRegTrx, filterByRegTrxType) {
			var convertedFormData = '';
			let regularTrx = {};

			if (this.preparer == current_user_name) {
				this.error_message = 'Cannot be approved by the same Preparer and Approver';
				this.approveBilling = false;
				this.snackbar = true;
			} else {
				//If current status is 5, the status will automatically proceed as released
				if (totalAmount > amountThreshold && filterByRegTrx.length < 1 && current_status !== 5 && filterByRegTrxType.length < 1) {
					//Go to Approver 2 or 3
					data.value.status = moveToStatus;
					data.value.headers = [];
					data.value.processing = 0;
					data.value.approved_remarks = '';
					data.value.approved_by = this.currUser.id;
					data.value.document_type = this.batchDetails.document_type;
					convertedFormData = jsonToFormData(data.value, options);
					this.$store.dispatch('trxbilling/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
				} else {
					//Go to Releasing
					this.documentDetails.filter(header => {
						this.dataRegularTrx.filter(trxdata => {			
							if (trxdata.doc_number === header.document_number) {
								regularTrx = {
									id: trxdata.id,
									doc_number: trxdata.doc_number,
									is_approved: 1
								};

								data.regular_trx_id = trxdata.id;
								this.$store.dispatch('regularTxns/updateregularTxns', regularTrx);
							}
						});
					});
					data.value.status = 7;
					data.value.headers = [];
					data.value.processing = 0;
					data.value.approved_remarks = '';
					data.value.approved_by = this.currUser.id;
					data.value.document_type = this.batchDetails.document_type;

					convertedFormData = jsonToFormData(data.value, options);
					this.$store.dispatch('trxbilling/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
				}	
			}
		},
		getDeptData(dept_id, index) {
			var filterDetails = this.billingDetails[0].details;
			if (filterDetails) {
				this.dataDepts.filter(depts_details => {
					if(depts_details.id === dept_id){			
						console.log(depts_details.sections);
						if (depts_details.sections.length > 0) {
							filterDetails[index].sections = depts_details.sections;
							filterDetails[index].section_id = filterDetails[index].sections.includes(filterDetails[index].section_id) ? filterDetails[index].sections : '';
						} else {
							filterDetails[index].sections = [];
							filterDetails[index].section_id = 0;
						}
					}

					if(dept_id == 1 || dept_id == 25 || dept_id == 26 || dept_id == 29){
						filterDetails[index].branches = this.branches;
					} else {
						filterDetails[index].branches = this.branches.filter(det => det.brnc_code === 'HOB');
					}
				});
			}
		},
		getServiceDetail(data){
			const remarks = ['Income Amount','Vat Amount','Withholding Tax','Total Amount'];
			//if the billing details does not have witholding tax
			if(!data.find((value) => value.remarks === 'Withholding Tax')){
				const remarks = ['Income Amount','Vat Amount','Total Amount'];
				const details = remarks.map((i) => data.find((j) => j.remarks === i));
				details.map((det) => {
					if(det.remarks === 'Total Amount'){
						det.remarks = 'Net Amount';
					}
				});
				return details;
			}
			const details = remarks.map((i) => data.find((j) => j.remarks === i));
			details.map((det) => {
				if(det.remarks === 'Total Amount'){
					det.remarks = 'Net Amount';
				}
			});
			return details;

			
		},
		reject(billing_request, current_status, status_type, billing_invoice_no) {
			let data = {};
			if(status_type === 'request_status' || current_status === 10 || current_status === 0){
				data.request_status = 10;
				data.billing_request_no = billing_request;
				data.approved_by = this.currUser.id;
				data.reject_remarks = this.rejectRemarks;
				data._method = 'PUT';
				data.update_category = 'request_status';
				this.$store.dispatch('trxbilling/updateBillingStatus', {id: billing_request, value: data});
			}else{
				data.accounting_status = 10;
				data.billing_request_no = billing_request;
				data.billing_invoice_no = billing_invoice_no;
				data.approved_by = this.currUser.id;
				data.reject_remarks = this.rejectRemarks;
				data._method = 'PUT';
				data.update_category = 'accounting_status';
				this.$store.dispatch('trxbilling/updateBillingAccountingStatus', {id: billing_request, value: data});
			}
		},
		extendDueDate(request_no){
			let data = {
				'due_date_extension': this.due_date_extension,
				'prepared_by': this.currUser.id,
				'_method': 'PUT',
				'update_category': 'extend'
			};
			this.$store.dispatch('trxbilling/updateBillingAccountingStatus', {id: request_no, value: data});
		},
		closeDialog() {
			this.$store.dispatch('trxbilling/setViewDialog', false);
		},
		formatAmount(amount){
			return numeral(amount).format('0,0.00');
		},
		getValue(tableData, columnId, id, column){
			for (var i=0; i < tableData.length; i++) {
				if (tableData[i][columnId] === id) {
					if (columnId === 'acct_code'){
						return tableData[i][columnId] + ' - ' + tableData[i][column];
					} else {
						return tableData[i][column];
					}
				}
			}
		},
		getService(service_id,payee_id){
			let service_data = this.services.filter(det => det.payee_id === payee_id);
			return this.getValue(service_data, 'id', service_id,'billing_service_name');
		},
		getDocuType(type){
			switch(type){
			case 'CR':
				return 'Cash Receipt';
			}
		},
		getTransType(type){
			switch(type){
			case 1:
				return 'Regular';
			case 2:
				return 'Non-Regular';
			}
		},
		getLabel(status){
			if(status == 6){
				return 'Release';
			} else {
				return 'Approve';
			}
		},
		printCR() {
			let item = this.documentDetails[0];
			let routeData = this.$router.resolve({path: '/preview-trxcr/'+btoa(JSON.stringify(item)), data: item});
			window.open(routeData.href, '_blank'); 
		},
		addEntry() {
			let data = {
				billing_service_id : this.billingDetails[0].details[0].billing_service_id,
				payee_id : this.billingDetails[0].details[0].payee_id,
				department_id: '',
				branch_id: '',
				section_id: '',
				account_code: '',
				debit_amount: 0,
				credit_amount: 0,
				remarks: this.billingDetails[0].remarks
			};
			this.billingDetails[0].details.push(data);
		},
		removeDetail(key) {
			this.billingDetails[0].details.filter((detail, index) => {
				if (index === key) {
					this.billingDetails[0].details.splice(key, 1);
				}
			});
		},
		handleFileUploads(){
			this.upload_files = this.$refs.files.files;
		},
		viewItem(item) {
			const details = this.billingDetailed.filter(detail => {
				if(detail.path === item){
					detail.seen = true;
				}
				return detail;
			});
			this.$store.commit('trxbilling/BILLING_DETAIL', details);

			//let url = process.env.VUE_APP_STORAGE_URL + '/' + this.billingDetails[0].billing_invoice_no + '/' + item;
			let path = atob(item);
			fetch(path)
				.then(res => res.blob()) // Gets the response and returns it as a blob
				.then(blob => {
					const objectURL = URL.createObjectURL(blob);
					window.open(objectURL, '_blank');
				});
			this.seenAttachedFile.push(item);
		},
		getDateFormat(date){
			return format(new Date(date), 'yyyy-MM-dd');
		},
		getRate(){
			let rate = 1;
			let dollar_rate = this.documentDetails[0].dollar_rate;
			let curr_code = 'P';
			let currentRate = [];
			let previousRate = [];
			let currency = [];
			let dateFormat = this.documentDetails[0].document_date;
			let currentMonth = getMonth(parseISO(dateFormat)) + 1;
			let currentYear = getYear(parseISO(dateFormat));

			if (dollar_rate !== '0.000000'){
				rate = dollar_rate;
			} else {
				currency = this.currencies.filter(det => {
					return det.id == this.documentDetails[0].currency_id;
				});

				if(currency.length > 0 ){
					curr_code = currency[0].cur_code;
				}

				currentRate = this.dataDollarRate.filter(det => {
					return det.period_no == currentMonth && det.fiscal_year == currentYear && det.cur_code == curr_code;
				});

				if(currentRate.length > 0){
					rate = parseFloat(currentRate[0].dolr_rate);
				} else {
					previousRate = this.dataDollarRate.filter(det => {
						return det.period_no == currentMonth && det.fiscal_year == currentYear && det.cur_code == curr_code;
					});

					if(previousRate.length > 0){
						rate = parseFloat(previousRate[0].dolr_rate);
					}
				}
			}

			return rate;
		},
		getCurrency(id){
			let curr_code = '';
			const currency = this.currencies.filter(det => det.id === id);

			if(currency.length > 0 ){
				curr_code = currency[0].cur_desc;
			}

			return curr_code;
		},
		getUser(id){
			let data = this.dataUsers.filter(det => det.id === id);
			if(data.length > 0){
				return data[0].user_first_name + ' ' + data[0].user_last_name;
			} else {
				return '';
			}
		},
		async getDataDollarRate() {
			this.dataDollarRate = this.dollarrates.data;
			this.nextPageDollarRate= this.dollarrates.next_page_url;
			let page = 2;

			while (this.nextPageDollarRate !== null){
				await this.$store.dispatch('alphataxcode/getData', page).then(response => {
					this.nextPageDollarRate = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataDollarRate.push(details);
					});
				});
				page++;
			}
		},
		async getDataAlphataxcodes(){
			this.dataAtc = this.alphataxcodes.data;
			this.nextPageAtc= this.alphataxcodes.next_page_url;
			let page = 2;

			while (this.nextPageAtc !== null){
				await this.$store.dispatch('alphataxcode/getData', page).then(response => {
					this.nextPageAtc = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataAtc.push(details);
					});
				});
				page++;
			}
		},
		async getDataDepts(){
			this.dataDepts = this.depts.data;
			this.nextPageDepts= this.depts.next_page_url;
			let page = 2;

			while (this.nextPageDepts !== null){
				await this.$store.dispatch('dept/getData', page).then(response => {
					this.nextPageDepts = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataDepts.push(details);
					});
				});
				page++;
			}
		},
		async getDataSections(){
			this.dataSections = this.sections.data;
			this.nextPageSections= this.sections.next_page_url;
			let page = 2;

			while (this.nextPageSections !== null){
				await this.$store.dispatch('section/getData', page).then(response => {
					this.nextPageSections = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataSections.push(details);
					});
				});
				page++;
			}
		},
		async getDataUsers(){
			this.dataUsers = this.users.data;
			this.nextPageUsers= this.users.next_page_url;
			let page = 2;

			while (this.nextPageUsers !== null){
				await this.$store.dispatch('user/getData', page).then(response => {
					this.nextPageUsers = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataUsers.push(details);
					});
				});
				page++;
			}
		},
		async getDataRegularTrx(){
			this.dataRegularTrx = this.regulartrx.data;
			this.nextPageRegularTrx= this.regulartrx.next_page_url;
			let page = 2;

			while (this.nextPageRegularTrx !== null){
				await this.$store.dispatch('regularTxns/getData', page).then(response => {
					this.nextPageRegularTrx = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataRegularTrx.push(details);
					});
				});
				page++;
			}
		}
	}
};
</script>
