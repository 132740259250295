<template>
	<v-container>
		<v-card>
			<v-card-title align="left" class="headline primary cash-receipt-title">
				<h5>Billing</h5>
			</v-card-title>
			<v-tabs background-color="primary accent-4" dark>	 
				<v-tab @click="trxnStatus = 1; trxnStatusName = 'Department'">Department</v-tab>
				<v-tab @click="trxnStatus = 2; trxnStatusName = 'Accounting'">Accounting</v-tab>
			</v-tabs>
			<v-data-table
				:headers="getHeader(trxnStatus)"
				:items="filteredItems"
				sort-by="document_number"
				class="elevation-1"
				:search="search"
			>
				<template v-slot:top>
					<v-toolbar flat color="white">
						<v-toolbar-title>{{trxnStatusName}}</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-layout wrap>
						<v-flex md3 />
						<v-spacer/>
						<v-flex xs12 sm6 md3>
							<v-text-field append-icon="mdi-calendar" label="Month and Year" required v-model="month_year" @click="month_year_picker = true" hide-details />
							<v-dialog width="25%" v-if="month_year_picker" v-model="month_year_picker"> 
								<v-date-picker
									v-model="month_year"
									type="month"
									@change="month_year_picker = false; getData()"
								></v-date-picker>
							</v-dialog>
						</v-flex>
						<v-spacer/>
						<v-flex xs12 sm6 md5>
							<v-text-field
								v-model="search"
								append-icon="search"
								label="Search"
								single-line
								hide-details
							></v-text-field>
						</v-flex>
						</v-layout>
					</v-toolbar>
				</template>
				<template v-slot:[`item.total_amount`]="{ item }">
					<span>{{formatAmount(item.total_amount)}}</span>
				</template>
				<template v-slot:[`item.action`]="{ item }">
					<template>
						<template v-if="trxnStatus === 1 && (item.request_status === 10 || item.request_status === 1) && parseInt(currUser.id) === parseInt(item.edit_by)">
							<v-icon color="primary" small @click="editItem(item)" class="mr-2" v-if="role_access > 2"
									>mdi-pencil</v-icon
								>
						</template>
						<template v-if="getApprover(item.request_status, item.dept_approver1, item.dept_approver2) && trxnStatus === 1">
							<v-icon color="primary" small @click="viewItem(item)" class="mr-2" v-if="role_access > 2"
									>mdi-clipboard-check-multiple-outline</v-icon
								>
						</template>
						<template v-else>
							<v-icon color="red" small class="mr-2" v-if="trxnStatus === 1"
									>mdi-clipboard-off-outline</v-icon
								>
						</template>
						<template v-if="acctApprover === true && trxnStatus === 2">
							<v-icon color="primary" small @click="viewItem(item)" class="mr-2" v-if="role_access > 2"
									>mdi-clipboard-check-multiple-outline</v-icon
								>
						</template>
						<template v-else>
							<!-- <v-icon color="red" small class="mr-2" v-if="trxnStatus === 2"
									>mdi-clipboard-off-outline</v-icon
								> -->
							<v-icon color="primary" small class="mr-2" @click="viewItem(item)" v-if="item.accounting_status === 7"
									>mdi-clipboard-outline</v-icon
								>
						</template>
						<template>
							<v-icon color="primary" small @click="printItem(item)" class="mr-2"
									>mdi-printer-outline</v-icon
								>
						</template>
						<template v-if="item.accounting_status === 7">
							<v-icon color="primary" v-if="item.billing_invoice_no.substring(0,3) != 'INV'" small @click="sendEmail(item)" class="mr-2">
								mdi-email-send-outline
							</v-icon>
							<v-icon color="primary" v-if="role_access === 5" small @click="voidTransaction(item)" class="mr-2">
								mdi-close-circle-outline
							</v-icon>
						</template>
						
						<template v-if="item.accounting_status === 20">
							<v-icon color="red" small class="mr-2">
								mdi-close-circle-outline
							</v-icon>
						</template>
					</template>
				</template>
			</v-data-table>


			<v-btn
				bottom
				fixed
				right
				fab
				dark
				@click="setBatchDocument"
				class="mx-2"
				color="primary"
				v-if="role_access > 1"
			>
				<v-icon dark>mdi-plus</v-icon>
			</v-btn>

			<div class="text-center">
				<v-dialog
					v-model="viewDialog"
					:items="batchDetails"
					:documentDetails="documentDetails"
					width="1200"
				>
					<v-trx-detail ref="detailbilling" :batchDetails.sync="batchDetails" :documentDetails.sync="documentDetails" :viewDialog.sync="viewDialog"></v-trx-detail>
				</v-dialog>
			</div>
			<div class="text-center">
				<v-dialog
					v-model="editDialog"
					:items="batchDetails"
					width="1200"
				>
					<v-trx-edit ref="editbilling" :batchDetails.sync="batchDetails" :editDialog.sync="editDialog"></v-trx-edit>
				</v-dialog>
			</div>
		</v-card>
		<div class="text-center">
			<v-dialog
				v-model="unsavedTransaction"
				persistent max-width="600"
			>
				<v-card>
					<v-card-title
						class="headline primary white--text"
						primary-title
					>
						Unsaved Transaction
					</v-card-title>
					<br>
					<v-card-text>
						You have unsaved transactions, Do you want to retrieve it?
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							text
							to="/trxcr/create"
						>
							Yes
						</v-btn>
						<v-btn
							color="red"
							text
							@click="removeTransaction()"
						>
							No
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<div class="text-center">
				<v-dialog
					v-model="collected"
					persistent max-width="600"
				>
					<v-card>
						<v-card-title
							class="headline primary white--text"
							primary-title
						>
							Unable to void this transaction.
						</v-card-title>
						<br>
						<v-card-text>
							Please void the collection for this transaction before voiding.
						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								text
								@click="collected = false"
							>
								OK
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
			<div class="text-center">
				<v-dialog
					v-model="showEmailType"
					persistent max-width="500"
				>
					<v-card>
						<v-card-title
							class="headline primary white--text"
							primary-title
						>
							Send Email
						</v-card-title>
						<br>
						<v-card-text>
							Kindly select type of email:
							<div class="flex-center">
								<v-radio-group
									v-model="emailType"
									row
								>
									<v-radio
									label="Initial Email"
									color="primary"
									value="0"
									></v-radio>
									<v-radio
									label="Email Reminder"
									color="primary"
									value="1"
									></v-radio>
								</v-radio-group>
							</div>
						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								text
								@click="sendEmailType(billing_no, emailType)"
							>
								Send
							</v-btn>
							<v-btn
								color="red"
								text
								@click="showEmailType = false"
							>
								Close
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
			<div class="text-center">
				<v-dialog
					v-model="voidBillDialog"
					persistent max-width="600"
				>
					<v-card>
						<v-card-title
							class="headline primary white--text"
							primary-title
						>
							Void Billing
						</v-card-title>
						<br>
						<v-card-text>
							Are you sure you want to void this transaction?
						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								text
								@click="voidInfoDialog = true;voidBillDialog = false"
							>
								Yes
							</v-btn>
							<v-btn
								color="error"
								text
								@click="voidBillDialog = false"
							>
								No
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
			<div class="text-center">
				<v-dialog
					v-model="voidInfoDialog"
					persistent max-width="600"
				>
					<v-card>
						<v-card-title
							class="headline primary white--text"
							primary-title
						>
							Void Billing
						</v-card-title>
						<br>
						<v-card-text>
							Invoice Number: <b>{{selectedItem.billing_invoice_no}}</b>
							<br>
							<br>
							Please state the reason:
							<v-text-field v-model="voidRemarks" dense required></v-text-field>
							File Upload:
							<div class="large-12 medium-12 small-12 cell">
								<label>
									<input type="file" id="files" ref="files" multiple v-on:change="handleFileUploads()"/>
								</label>
							</div>
						</v-card-text>
						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								text
								@click="cancelTransaction()"
							>
								Confirm
							</v-btn>
							<v-btn
								color="error"
								text
								@click="voidInfoDialog = false"
							>
								Cancel
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
			<div class="text-center">
				<v-dialog
					v-model="createBillDialog"
					persistent max-width="600"
				>
					<v-card>
						<v-card-title
							class="headline primary white--text"
							primary-title
						>
							Billing
						</v-card-title>
						<br>
						<v-card-text>
							Sorry, the accounting period for this month is already <b>CLOSED</b>.
						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								text
								@click="createBillDialog = false"
							>
								OK
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
		</div>
	</v-container>
</template>

<script>
import numeral from 'numeral';
import detailBilling from './detailBilling.vue';
import editBilling from '@/components/pages/trx/billing/edit/editBilling.vue';
import { mapGetters } from 'vuex';
import { format, getYear, getMonth, parseISO } from 'date-fns';
import jsonToFormData from 'json-form-data';
// import io from 'socket.io-client'; 

export default {
	components: {
		'v-trx-detail': detailBilling,
		'v-trx-edit': editBilling
	},
	data() {
		return {
			module_name: 'Billing',
			voidBillDialog: false,
			voidInfoDialog: false,
			voidRemarks: '',
			selectedItem: '',
			role_access: 1,
			trxnStatus:1,
			trxnStatusName: 'Department',
			search: '',
			month_year: '',
			emailType : '0',
			month_year_picker: false,
			currentUserFullName: '',
			unsavedTransaction: false,
			collected: false,
			isApprover: false,
			batchDetails: {},
			documentDetails:[],
			fileDetails: [],
			data: [],
			dataCoas: [],
			nextPageCoas: '',
			nextPage: '',
			nextPageTrx: '',
			dataOfficer: [],
			nextPageOfficer: '',
			deptApprover: false,
			acctApprover: false,
			showEmailType: false,
			createBillDialog: false,
			billing_no: '',
			upload_files: '',
		};
	},
	computed: {
		...mapGetters({
			billings: 'trxbilling/billings',
			headers: 'trxbilling/headers',
			headersBilling: 'trxbilling/headersBilling',
			editedItem: 'trxbilling/editedItem',
			defaultItem: 'trxbilling/defaultItem',
			dialog: 'trxbilling/dialog',
			editedIndex: 'trxbilling/editedIndex',
			editDialog: 'trxbilling/editDialog',
			viewDialog: 'trxbilling/viewDialog',
			attachmentDialog: 'trxbilling/attachmentDialog',
			valid: 'trxbilling/valid',
			currUser: 'auth/currUser',
			approvingofficers: 'approvingofficer/approvingofficers',
			dist_data: 'trxbilling/dist_data',
			acctperiodmains: 'acctperiodmain/acctperiodmains',
			coas: 'coa/coaSubs',
			coaData: 'coa/coaData',
			payees: 'payefile/payefiles',
			billingapprovingofficers: 'billingapprovingofficers/approvingofficers',
			billingRequestNumber: 'trxbilling/billingRequestNumber',
			billingNumber: 'trxbilling/billingNumber'
		}),
		years() {
			const year = new Date().getFullYear();
			const generateYears = Array.from({ length: year - 1900 },(value, index) => 1901 + index);
			generateYears.sort();
			return generateYears.reverse();
		},
		filteredItems() {
			let app = this;
			let txn = this.billings;
			let txnFiltered = [];
			if(txn){
				txn.forEach(det => {
					let payee = this.payees.filter(pay => pay.id == det.payee_id);
					if(payee.length > 0){
						det.customer_name = payee[0].payee_desc;
					}
					if(det.request_status === 10 ){
						det.status = 'Rejected';
					}
					if(det.request_status === 1 ){
						det.status = 'Draft';
					}
					if(det.request_status === 20 ){
						det.status = 'Cancelled';
					}
					if(det.request_status === 4 ){
						det.status = 'Level 1 - For Approval';
					}
					if(det.request_status === 5 ){
						det.status = 'Level 2 - For Approval';
					}
					if(det.request_status === 7 ){
						det.status = 'Approved';
					}
					if(det.accounting_status === 3 ){
						det.status = 'Level 1 - For Approval';
					}
					if(det.accounting_status === 4 ){
						det.status = 'Level 2 - For Approval';
					}
					if(det.accounting_status === 5 ){
						det.status = 'Level 3 - For Approval';
					}
					if(det.accounting_status === 10 && det.request_status < 10 ){
						det.status = 'Rejected - Accounting Approver';
					}
					if(det.accounting_status === 20 ){
						det.status = 'Void';
					}
					if(det.request_status === 7 && det.accounting_status === 7){
						det.status = 'Approved';
					}
					det.total_amount = parseFloat(det.amount.replace(/,/g, ''));
					det.request_date = format(new Date(det.request_date), 'yyyy-MM-dd');
					// det.billing_date = format(new Date(det.billing_date), 'yyyy-MM-dd');
					if(det.amount_collected === 0){
						det.collection_status = 'Uncollected';
					} else {
						if(det.total_amount - det.amount_collected === 0){
							det.collection_status = 'Fully Collected';
						} else {
							det.collection_status = 'Partially Collected';
						}
					}
					if(det.request_status === 7){
						if(this.currUser.min_amount){
							this.setApprover('acctApprover');
						} 
					}
				});
				let deptList = [];
				let acctList = [];
				txn = Object.values(txn);
			
				if (txn !== undefined) {
					txn.forEach(element => {
						if (element !== null && element.request_status !== 20 ) {
						// if(element.processing < 1){

							if(element.accounting_status !== 0  && element.accounting_status !== 10){
								acctList.push(element);
							} else {
								deptList.push(element);
							}
						}
					

						switch(app.trxnStatus)
						{
						case 1:
							txnFiltered = deptList;
							break;
						case 2:
							txnFiltered = acctList;
							break;
						} 
					});	
				}
			}
			return txnFiltered;
		}
	},
	watch: {
		editedIndex: {
			handler() {
			},
			deep: true
		},
		billings: {
			handler() {
				
			},
			deep: true
		},
		coas: {
			handler(){
				this.getDataCoas();
			}
		},
		billingapprovingofficers: {
			handler(){
				this.getDataOfficers();
			}
		},
		acctperiodmains:{
			handler(){
				this.getAcctperiodmain();
			}
		}
	},
	created() {
		// this.socket = io(process.env.VUE_APP_SOCKET_URL); 
	},
	mounted() {
		const that = this;
		let date = format(new Date(), 'yyyy-MM-dd');
		let month = getMonth(parseISO(date)) + 1;
		let year = getYear(parseISO(date));
		let i = (month.toString().length == 2 ? '-' : '-0');
		that.month_year = year + i + month;
		
		this.$store.dispatch('trxbilling/getBillings', {request_date: that.month_year + '-01'}); //, {document_date: that.month_year + '-01'}
		this.$store.dispatch('payefile/getPayefileDropdown');// this.$store.dispatch('acctperiodmain/getAcctperiodmains');
		this.$store.dispatch('billingapprovingofficers/getApprovingOfficers');
		this.$store.dispatch('coa/getCoaDropdown');
		// this.socket = io(process.env.VUE_APP_SOCKET_URL); 

		
		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}

		this.$data.currentUserFullName = this.currUser.user_last_name + ', ' + this.currUser.user_first_name;
		if (this.currUser.max_amount !== null) {
			this.isApprover = true;
		} else {
			this.isApprover = false;
		}
	},
	methods: {
		getApprover(status, approver1, approver2){
			let approver = this.dataOfficer.filter(officer => officer.approver_name === this.currUser.user_first_name + ' ' + this.currUser.user_last_name);
			if(approver.length !== 0){
				// let min_amount = parseFloat(approver[0].min_amount.replace(/,/g, ''));
				// let max_amount = parseFloat(approver[0].max_amount.replace(/,/g, ''));
				if(status === 4 && approver[0].level === 1 && approver1 === this.currUser.id){
					return true;
				}
				else if(status === 5 && approver[0].level === 2  && approver2 === this.currUser.id){
					return true;
				}
				else if(status === 7 && approver[0].level === 3){
					return true;
				}
				else{
					return false;
				}
			}
		},
		setApprover(item){
			this[item] = true;
		},
		handleFileUploads(){
			this.upload_files = this.$refs.files.files;
		},
		getHeader(status){
			if(status === 1){
				return this.headers;
			} else{
				return this.headersBilling;
			}
		},
		async downloadItem(item) {
			this.$data.batchDetails = [];
			this.documentDetails = [];
			this.$data.batchDetails.batch_number = item.batch_number;
			this.$data.batchDetails.total_amount = item.total_amount;

			let disbList = this.billings;
			disbList = Object.values(this.billings);

			this.$store.dispatch('trxbilling/setEditedIndex', disbList.indexOf(item));
			this.$store.dispatch('trxbilling/setAttachmentDialog', true);
			await this.$store.dispatch('trxbilling/getDetails', item.batch_number);
		},
		async viewItem(item) {
			this.batchDetails = [];
			this.batchDetails = item;
			this.documentDetails = {};
			let approver = this.dataOfficer.filter(officer => officer.approver_name === this.currUser.user_first_name + ' ' + this.currUser.user_last_name);
			if(approver.length !== 0){
				let min_amount = parseFloat(approver[0].min_amount.replace(/,/g, ''));
				let max_amount = parseFloat(approver[0].max_amount.replace(/,/g, ''));
				
				this.documentDetails.min_amount = min_amount;
				this.documentDetails.max_amount = max_amount;
			}
			let disbList = this.billings;
			disbList = Object.values(this.billings);
			if(item.request_status === 7 && this.billingNumber === '' && item.accounting_status === 0){
				// await this.$store.dispatch('trxbilling/getBillingNumber', { edit_by: this.currUser.id });
				
				let payload = { document_date: format(new Date(), 'yyyy-MM-dd') };
				await this.$store.dispatch('trxdisb/getDocumentNumberOR', payload).then(response => {
					if(response.data.data.length === 0){
						this.snackbar = true;
						this.$data.error_message = 'No series for Invoice.';
					} else {
						const receipt_number = response.data.data[0].or_number;
						this.$store.commit('trxbilling/SET_BILLING_NUMBER', receipt_number, { root: true });
					}
				});
			}
			this.$store.dispatch('trxbilling/setEditedIndex', disbList.indexOf(item));
			this.$store.dispatch('trxbilling/setEditedItems', item);
			this.$store.dispatch('trxbilling/setPreparer', item.preparer);
			this.$store.dispatch('trxbilling/getBilling', item.billing_request_no);
			await this.$store.dispatch('trxbilling/getDetails', item.billing_request_no);
			this.$store.commit('trxbilling/BILLING_DETAIL_MODAL_VIEW', true);
			// this.updateSocket(item);
		},
		async printItem(item) {
			if(item.billing_invoice_no.substring(0,3) === 'INV'){
				console.log(item);
				if(item.request_status === 7 && item.accounting_status < 7){
					item.table = 'billing';
					let routeData = this.$router.resolve({path: '/preview-bill-ereceipt/'+btoa(JSON.stringify(item)), data: item});
					window.open(routeData.href, '_blank');
				} else if ( item.accounting_status <= 7) {
					const res = await this.$store.dispatch('trxbilling/getEreceiptData', item.billing_invoice_no);
					var newBlob = new Blob([res], { type: 'application/pdf' });
					var newFileURL = URL.createObjectURL(newBlob);
					window.open(newFileURL, '_blank');
				}
			} else {
				let routeData = this.$router.resolve({path: '/preview-bill-ereceipt/'+btoa(JSON.stringify(item)), data: item});
				window.open(routeData.href, '_blank');
			} 
		},
		sendEmail(item){
			if(item.accounting_status === 7){
				this.showEmailType = true;
				this.billing_no = item.billing_invoice_no;
			} else {
				this.$store.dispatch('trxbilling/sendEmail', { billing_invoice_no: item.billing_invoice_no, follow_up: 0});
			}
		},
		sendEmailType(invoice, type) {
			this.$store.dispatch('trxbilling/sendEmail', { billing_invoice_no: invoice, follow_up: type});
			this.showEmailType = false;
		},
		formatAmount(amount){
			return numeral(amount).format('0,0.00');
		},
		setBatchDocument(){
			if(this.billingRequestNumber === ''){
				this.$store.dispatch('trxbilling/getRequestNumber', { edit_by: this.currUser.id });
			} else {
				this.$router.push('/trx-billing/create');
			}

		},
		async editItem(item) {
			this.batchDetails = [];
			this.documentDetails = [];
			this.batchDetails.billing_request_no = item.billing_request_no;

			let billingListEdit = this.billings;
			billingListEdit = Object.values(this.billings);

			this.$store.dispatch('trxbilling/setEditedIndex', billingListEdit.indexOf(item));
			this.$store.dispatch('trxbilling/setEditedItems', item);
			this.$store.dispatch('trxbilling/setEditDialog', true);
			this.$store.dispatch('trxbilling/getBilling', item.billing_request_no);
		},
		removeTransaction(){
			this.unsavedTransaction = false;
		},
		async getAcctperiodmain(){
			this.data = this.acctperiodmains.data;
			this.nextPage= this.acctperiodmains.next_page_url;
			let page = 2;

			while (this.nextPage !== null){
				await this.$store.dispatch('acctperiodmain/getData', page).then(response => {
					this.nextPage = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.data.push(details);
					});
				});
				page++;
			}
		},
		async getDataCoas(){
			if(this.coaData.length === 0){
				this.dataCoas = this.coas.data;
				this.nextPageCoas = this.coas.next_page_url;
				let page = 2;

				while (this.nextPageCoas !== null){
					await this.$store.dispatch('coa/getCoaData', page).then(response => {
						this.nextPageCoas = response.data.data.next_page_url;
						response.data.data.data.forEach(details => {
							this.dataCoas.push(details);
						});
					});
					page++;
				}
				
				let data = this.dataCoas.filter(e => parseInt(e.acct_status) === 1);
				this.$store.commit('coa/SET_COA_DATA', data);
			}
		},
		async getDataOfficers(){
			this.dataOfficer = this.billingapprovingofficers.data;
			this.nextPageOfficer = this.billingapprovingofficers.next_page_url;
			let page = 2;

			while (this.nextPageOfficer !== null){
				await this.$store.dispatch('billingapprovingofficers/getData', page).then(response => {
					this.nextPageOfficer = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataOfficer.push(details);
					});
				});
				page++;
			}
		},
		getData(){
			this.$store.dispatch('trxbilling/getBillings', {request_date: this.month_year + '-01'});
		},
		checkBookOpen(date){
			let currentMonth = getMonth(parseISO(date));
			let currentYear = getYear(parseISO(date));

			let isBookOpen = true;
			
			this.data.forEach(account_period => {
				if (account_period.period_no === (currentMonth + 1) && account_period.fiscal_year === currentYear) {
					if (account_period.general_journal === 'CLOSED') {
						isBookOpen = false;
					} else {
						isBookOpen = true;
					}
				} 
			});

			return isBookOpen;
		},
		voidTransaction(item) {
			let date = format(new Date(), 'yyyy-MM-dd');
			this.selectedItem = item;
			this.voidRemarks = '';
			this.upload_files = '';
			let isBookOpen = this.checkBookOpen(date);

			if(item.amount_collected > 0 ){
				this.collected = true;
			} else {
				if (isBookOpen === true) {
					this.voidBillDialog = true;
				} else {
					this.createBillDialog = true;
				}
			}
		},
		cancelTransaction(){
			var options = {
				initialFormData: new FormData(),
				showLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};
			let data = {};
			data.billing_invoice_no = this.selectedItem.billing_invoice_no;
			data.accounting_status = 20;
			data.remarks = this.voidRemarks;
			data.document_type = 'JE';
			data.image_title = [];
			data.upload_file = [];
			data.approved_by = this.currUser.id;
			for( var i = 0; i < this.upload_files.length; i++ ){
				let file = this.upload_files[i];
				data.image_title.push(file.name);
				data.upload_file.push(file);
			}
			let convertedFormData = jsonToFormData(data, options);
			this.$store.dispatch('trxbilling/setisLoading', true);
			this.$store.dispatch('trxbilling/voidBilling', convertedFormData);
					
		},
	}
};

</script>

<style scoped>
	.cash-receipt-title h5 {
		color: #FFF;
		font-size: 25px;
	}
	
	.date-filter {
		display: flex;
		flex-direction: row;
		position: relative;
		top: 10.5px; 
		right: 10px;
	}
	.date-filter-picker {
		width: 150px;
	}
	.date-filter-picker:first-child {
		margin-right: 10px;
	}

	.document-card-title {
		padding: 0;
	}
	.flex-center {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
</style>
